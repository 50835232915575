.content-text-heads{
    font-weight: bold;
}
.content-input-field{
    /* margin-left: 16px; */
}
.content-save-btn{
    border-radius: 2px;
    width: auto !important;
    margin-left: 16px;
}
.content-form{

}
.content-dropdown{
    padding: 2px !important;
}
.content-input-group{
    position: relative;
    display: table;
    border-collapse: separate;
    width:100%;
}
.video-container{
    border-radius: 5px;
    border:1px solid grey;
}
.order-input{
    width: 60px !important;
}
.content-uploader-field{
    margin-left: 0px;
}
.content-save-btn{
    border-radius: 2px !important;
    width: auto !important;
    margin-left: 16px;
}
.content-back-btn{
    border-radius: 2px !important;
    width: auto !important;
    margin-left: 16px;
}
.content-btn-submit {
    display: flex;
}
.content-image-container{
    width: 50px !important;
    height: 50px !important;
    border-radius:5px !important;
}

img{
    width: 50px !important;
    height: 50px !important;
    padding:2px;
    border-radius: 5px;
    margin-right: 2px;
}
.content-image-uploader{
    display: flex;
    /* margin-left: 0px; */
}
.content-seperator{
  color: lightgrey;
  border-top: 1px solid;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.remove-content{
    cursor: pointer;
}
.hide-control{
    display: none;
}
/* .loader-style{
    position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0,0,0,0.3);
      z-index:1;
      text-align:center;
      padding: 50;
} */