.login-background{
    background-image: url('../../Assets/Images/adminbg.png');
    background-repeat: round;
}
body{
    font-family: sans-serif;
}
.mai-login .splash-container {
    padding-top: 0px;
        width: 100%;
        height:100vh;
        margin: 0 auto;
        /*background-color: #072667;*/
    }
    .mai-login .splash-container .user-message {
        background-color: transparent;
        padding-left:15px;
    }
    .mai-login .splash-container .login-form-main {
        /* background-color: #072667; */
        /* padding-top: 30px; */
         background-image: url('../../Assets/Images/adminbg.png');
            background-repeat: round;
    }
    .mai-login .splash-container .login-form-main .form-message {
        /* background-color: #072667; */
        padding-top: 0px;
         
    }
    .mai-login .login-form-main .form-message .ant-spin-container img.logo-img {
        /* padding-bottom: 90px; */
    }
    .img-fluid {
        max-width: 100%;
        height: auto
    }
    .color-red {
        color: red;
    }
    .logo-center{
        text-align: center;
    }
    .footer-logo-center{
        text-align: center;
        bottom: 10px;
    position: absolute;
    width: 100%;
    color: white;
    left:0px;
    font-family:sans-serif;
        
    }
    .login-validation{
        color: red;
        position: absolute;
        font-size: 11px;
        margin-top: -15px;
    }
    .logo-top-space{
        padding-top: 80px;
    }
    .padding-space{
        padding-right: 30px;
    }
    .login-content-width{
        width: 50%;
    }
    .login-footer-content-width{
        width: 70%;
        margin-left: 40px;
        
    }
    .login-content{
        padding-left: 60px;
    }
    .login-footer-image{
        /* margin-left: -30px; */
        width: auto !important;
    height: auto !important;
    text-align: center;
        /* width: 70%; */
    }
    .login-form{
        /* padding: 0px 35px 0px 35px; */
        margin-left:30% ;
        margin-right:30%
    }
    .login-content-container{
        position: relative;
        width: 50%;
        margin-left: 25%;
    }
    .forgot-password-style{
        cursor: pointer;
        border-radius: 2px !important;
        font-size:12px
      }
    .login-btns{
        width: 100%;
        border-radius: 2px !important;
        height: 2.5rem;
        cursor: pointer;
        border-radius: 2px !important;
        border:0px;
    }
   .login-input-field{
        
        height: 2.0rem !important;
    font-size: 12px;
    border-radius: 0px 2px 2px 0px !important;
    border:0px
   }
   .input-icon-style{
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: aliceblue;
    border: 1px solid #ccc;
    border-radius: 2px;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  
}
   .login-forgot-password{
margin-top:-20px;
font-family: sans-serif;
text-align: right;
   }
   .login-input-group{
    /* padding: 6px ;
    display: table-cell;
     */
     position: relative;
    display: table;
    border-collapse: separate;
    padding: 20px 0 20px 0 ;
    /* width:70%;
    margin-left:15%; */
   }
   .logo-tag-line{
    font-family:  sans-serif;
    font-size: large;
    margin: 30px 0 25px 0;
    color:white;
   }
     /* --------------Media queries------------------------- */
    @media screen and (max-width: 767px) {
        .mai-login .splash-container {
            /* background-color: #072667; */
            background-image: url('../../Assets/Images/adminbg.png');
            background-repeat: round;
        }
        .mai-login .splash-container .login-form-main {
            /* padding-top: 30px; */
        }
        .mai-login .login-form-main .form-message .ant-spin-container img.logo-img {
            /* padding-bottom: 30px; */
        }
    }
    
  