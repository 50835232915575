.send-container{
    padding: 0 20% 0 20%;
}
.send-btn{
    width: 100%;
}
.reset-input-field{
    height: 3.0rem !important;
    font-size: 12px;
    border-radius: 0px 2px 2px 0px !important;
    border:0px
}
.out-links {
    position:  relative !important;
}