.dashboard-header{
    background-color: lightgray;
    color:black;
}
.dashboard-block{
    background-color: #bdd0da;
    border-radius: 2px;
    margin:5px 0 5px 0;
    padding: 5px;
}
.pernding-user-cell-container{
    white-space: normal;
    height: 100%;
    width: 100%;
    border: 2px solid grey;
    border-style: ridge;
    box-sizing: border-box;
    padding: 5px;
    background-color: darkgray;
}
.pagination {
    margin-left: 30px !important;
}
.page-item{
    width: 35px;
    height: 35px;
    text-align: center;
}