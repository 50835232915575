.group-text-heads{
    font-weight: bold;
}
.group-input-field{
    /* margin-left: 16px; */
}
.group-uploader-field{
    margin-left: 0px;
}
.group-save-btn{
    border-radius: 2px !important;
    width: auto !important;
    margin-left: 16px;
}
.group-back-btn{
    border-radius: 2px !important;
    width: auto !important;
    margin-left: 16px;
}
.group-btn-submit {
    display: flex;
}
.group-form{

}
.group-dropdown{
    padding: 2px !important;
}
.group-input-group{
    position: relative;
    display: table;
    border-collapse: separate;
    width:100%;
}
.group-image-container{
    width: 50px;
    height: 50px;
    border-radius:5px;
}
.group-image-uploader{
    display: flex;
    /* margin-left: 0px; */
}
.group-seperator{
  color: lightgrey;
  border-top: 1px solid;
  margin-top: 1rem;
  margin-bottom: 1rem;
}