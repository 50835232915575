.organisation-text-heads{
    font-weight: bold;
}
.organisation-input-field{
    /* margin-left: 16px; */
    /* width: 400px !important; */
}
.organisation-save-btn{
    border-radius: 2px !important;
    width: auto !important;
    margin-left: 16px;
}
.organisation-back-btn{
    border-radius: 2px !important;
    width: auto !important;
    margin-left: 16px;
}
.organisation-btn-submit {
    display: flex;
}
.organisation-form{

}
.organisation-input-group{
    position: relative;
    display: table;
    border-collapse: separate;
}
.organisation-seperator{
    color: lightgrey;
    border-top: 1px solid;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .react-checkbox-tree {
      height: 300px;
  }
  .rct-title{
      margin-left: 5px;
      font-weight: normal;
  }
  .react-checkbox-tree> ol{
      max-height: 300px;
      overflow-y:scroll;
      padding-left: 0px !important;
  }
