.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}body{
  font-family: sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.search-bar{
  border-width:2px  !important;
  border-radius: 30px;
  border-color: #bdd0da !important;
  background-color: transparent !important;
}
.search-bar-icon{
 color:#bdd0da !important; 
 right:13%!important;
}
.layout-logo{
  height: 30px;
    width: 150px;
    margin-top: -5px;
}
::placeholder{
  color:#bdd0da !important;
}
.dashboard-container{
  padding-top:0px !important;
}
.container-width{
  width:50%;
}
.group-level-first{
  /* border:1px black solid; */
  border-radius: 2px;
  padding: 5px;
  margin:10px 0px 10px 0px;
  background: aliceblue;
}

.previous-video{
  position: absolute; /*or fixed*/
  left: 0px;
  float: left;
  width: 100px;
  top:50%;
  /* border: 1px solid #101010; */
  background:#f2f2f2;
  border-radius: 2px;
  padding:8px;
}
.next-video{
  position: absolute; /*or fixed*/
  right: 0px;
  float: right;
  top:50%;
  width: 100px;
  /* border: 1px solid #101010; */
  border-radius: 2px;
  background: aliceblue;
  padding:8px;
}
.sub-video-container{
  height: 55px;
  border-radius: 2px;
/* background-image:  url('http://dev-s3-tabnu-training-client.s3-website-eu-west-1.amazonaws.com/thumbnail/Dual axis chart.PNG') ; */
}
.icon-bg-color{
  background-color: white;
}
.bg-gray7 {
  background-color: #495057;
}
.bg-blue7 {
  background-color: #053463;
}
.white {
  color: #fff;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.flex {
  display: flex;
}
.tabnu-logo{
    width:260px !important;
}
.apply-hand{
  cursor: pointer;
  border-radius: 2px !important;
}
.dropdown-toggle:after {
  display: inline-block !important;
}
.footer-left-section{
  position: absolute; 
  left: 0px;
  float: left;
  padding: 8px;
  color: #cccccc;
  font-size: large;
    font-weight: bold;
}
.footer-right-section{
  position: absolute;
    right: 0px;
    float: right;
    padding: 8px;
}
.sub-group-container{
  width: 96%;
  margin-left: 2%;
}
.dashboard-sub-group-right-panel{
  font-size: 10px;
  color: grey;
}
.dashboard-language{
  color: white;
  margin-top: 25px;
}
.completed-video{
  background-color: blue;
  color:white;
  padding: 1px 5px 2px 5px;
  border-radius: 50%;
}
.dashboard-footer-logo{
  height: 30px !important;
  width: auto !important;
    margin-bottom: 15px;
}
.dashboard-group-seperator{
  color: lightgrey;
  border-top: 1px dashed;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.search-input{
  margin: auto;
  width: 75% !important;
  padding: 10px !important;
  border-radius:50px !important;
  border-color: #bdd0da !important;
}
.sub-group-description{
  font-size: 12px;
  cursor: pointer;
}
.sub-description{
  font-size: 14px;
  cursor: pointer;
}
.dropdown-menu{
  border:1px grey solid  !important;
  background-color: white !important;
  color: black  !important;
}
.right-banner-img{
  margin: 15% 0 15% 0;;
}
.container{
  font-family: sans-serif;
}
.top-space{
  margin-top: 25px;
}
.header-left-logo{
  right:30px;
}
.dashboad-container{
  margin: 0px auto;
  width: 36%;}
  .left-menu{
    border:2px solid grey !important;
  }
  .message-validation{
    color: red;
    position: absolute;
    font-size: 11px;
    margin-top: -15px;
  }
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.display-container{
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    /* font-size: 3rem; */
}
.action-icon-space{
  margin-left: 15px;
}
.loader-style{
    position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0,0,0,0.3);
      z-index:1;
      text-align:center;
      padding: 50px;
}
/* -------------------------- */
.alain-default__header {
  /* background-image: url('./Assets/Images/ui/tabnuTempLogo.png'); */
  background-color: #043a7a;	
  background-repeat: no-repeat;	
  background-position: center center;
  display:block;
}